body {
    background-color: #333;
    margin: 0;
    background-color: #000000;
    font-family: 'Dancing Script', cursive;
}

.split-div {
    display: flex;
    background: #000;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.cover-div {
    background: #000;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.image {
    object-fit: cover;
    object-position: 10%;
    width: 100%;
    height: 100%;
}

.content {
    flex: 1;
    overflow: hidden;
}

.text {
    flex: 1;
    color: #888;
    font-family: 'Dancing Script', cursive;
    display: flex;
    background-size: 6.93px 12px;
    background-image: linear-gradient(120deg, #0f0f0f 8.33%, #141414 8.33%, #141414 50%, #0f0f0f 50%, #0f0f0f 58.33%, #141414 58.33%, #141414 100%);
}

.text p {
    padding: 20px;
    font-size: 5vh;
    margin: 0px;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
}

.text h1 {
    padding: 20px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.divider {
    position: absolute;
    opacity: .6;
    background-color: #000;
    width: 4px;
    left: calc(100vw - 50% - 2px);
    height: 100vh;
}

.frame {
    position: absolute;
    opacity: .5;
    background-color: transparent;
    border: solid 2px #000;
    width: calc(100vw - 4px);
    height: calc(100vh - 4px);
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 20px;
  top: 20px;
  opacity: .5
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  padding: 10px;
  font-size: 1.15em;
  background: #000;
  opacity: .9;
  font-family: 'Dancing Script', cursive;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0px 0 20px;
  border-bottom: solid 1px #111
}

.bm-item:last-child {
  border: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.thumbnail {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-size: 50px;
  position: relative;
  top: 12px;
  margin-right: 20px;
  border-radius: 5px;
}

.cover-text {
  position: absolute;
  width: calc(100vw - 50%);
  padding: 20px;
  background: #000;
  opacity: .8;
  color: #fafafa;
  text-align: center;
  top: calc(100vh - 80% + 40px);
  left: calc(100vw - 77%);
  border: solid 4px #111;
  border-radius: 2px;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
  overflow: visible !important;
}